@import '../../../styles/global'
	
.tags-list
	&__list
		display: flex
		flex-wrap: wrap
		gap: .5rem
		margin-top: .4rem

		&--small
			gap: .3rem

	.tags-list__tag
		position: relative
		padding-right: .5rem
		border-radius: 1rem
		background-color: $color-primary

		.tags-list__edit-btn
			color: rgba(#fff, .7)

			&:hover
				color: #fff

			&:disabled
				color: rgba(#fff, .3)
