
@import '../../../styles/global'

.vr-package
	min-width: 80vw

	h2
		margin-bottom: 1rem

	&__actions
		display: flex
		justify-content: flex-end
		align-items: flex-start
		gap: 1rem

	&__full-info
		display: block
		max-width: 28.125rem

	&__list-item
		border-top: 1px solid $color-border

		&:last-child
			border-bottom: 1px solid $color-border

		.flex-container
			margin: 0

	&__btn-container
		position: absolute
		top: .8rem
		right: 0

	&__qr
		width: 100%
		max-width: 31.25rem
		height: auto
		margin: 1rem auto 0

	.vr-package__icon
		margin-right: .3rem
		font-size: 1rem
		vertical-align: text-bottom

	.MuiListItemText-secondary
		display: flex
		align-items: center
		gap: 1rem
		margin-top: .8rem

	.flex-container--between
		align-items: flex-start
		margin-bottom: 1rem
		flex-wrap: wrap

		button
			flex-shrink: 0
