@import '../../../styles/global'

.outer-list-item
	border-top: 1px solid $color-border

	&:last-child
		border-bottom: 1px solid $color-border

	*
		word-break: break-word

	.outer-list-item
		&__button
			display: flex
			width: 100%
			padding: 0
			opacity: .4

		&__icon
			min-width: 1.25rem
			margin-right: .5rem
