@import '../../../styles/global'

.breadcrumbs
	margin-bottom: 1rem

	&__list-element,
	&__link
		max-width: 280px
		overflow: hidden
		text-overflow: ellipsis

	&__list-element
		font-weight: 600
		color: $color-primary

	&__link
		display: block
		border-bottom: 1px solid transparent
		white-space: nowrap

		&:hover
			border-bottom: 1px solid #000
