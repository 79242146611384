@import '../../styles/global'
	
.photos--form
	justify-content: center

.photos,
.photos--form
	display: flex
	flex-wrap: wrap
	gap: 1rem
	margin: 1rem auto

	&__header
		width: 100%

	&__img-container
		position: relative
		display: flex
		flex-direction: column
		gap: .5rem
		width: 12.5rem
		padding: .5rem
		border: 1px solid $color-border
		border-radius: 0.5rem
		font-size: .875rem

		.photos__tag
			font:
				size: .7rem
				weight: $weight-bold

	&__img-wrapper
		display: flex
		justify-content: center
		align-items: center
		flex-direction: column
		width: 100%
		height: 12.5rem

	&__img
		display: block
		width: auto
		height: auto
		max-height: 12.5rem
		max-width: 100%
		object-fit: cover
		margin-bottom: .5rem

	&__loading
		display: flex
		justify-content: center
		align-items: center
		flex-direction: column
		gap: .8rem
		width: 12.5rem
		height: 12.5rem
		border: 1px solid $color-border
		border-radius: 0.5rem
		background-color: rgba($color-border, .2)

	&__text
		color: $color-text-light
		word-break: break-word

	&__in-component-info
		display: flex
		justify-content: center
		align-items: center
		width: 1.875rem
		height: 1.875rem
		position: absolute
		top: -1px
		left: -1px
		padding: 0 .3rem
		border-radius: .5rem
		color: #fff
		background-color: $color-success
		font:
			size: .8rem
			weight: $weight-bold
		text-align: center

		span
			display: none
		
		&:hover span
			display: block
			position: absolute
			top: 2rem
			padding: 0 .2rem
			border-radius: .3rem
			background-color: $color-text-dark

	&__accent-info
		font-weight: $weight-bold
		color: $color-primary
		text-align: right

	&__preview
		position: relative
		max-width: 100%

		img
			display: block
			width: auto
			height: auto
			max-width: 100%
			max-height: 68vh
			margin: 0 auto .5rem
			object-fit: cover

	&__preview-name
		font:
			size: 1rem
			weight: $weight-bold

	&__preview-desc
		font-size: .875rem
		color: $color-text-light
		word-break: break-word

	&__search
		position: relative
		min-width: 25rem

		input.MuiOutlinedInput-input
			padding: .5rem 1rem

		label.MuiInputLabel-root
			top: -.5rem

		.flex-container
			justify-content: flex-end

		.MuiTextField-root
			flex-grow: 1

	&__query-info
		position: absolute
		top: 3.5rem
		left: 0
		width: 100%

	&__basic-container
		margin: 1rem 0

	&__tags-switch
		padding: .3rem .5rem
		border-radius: .5rem
		background-color: $color-primary-light
		margin-bottom: .5rem

	.photos__remove-btn
		position: absolute
		top: 0
		right: 0
		border-radius: .5rem
		background-color: rgba(#fff, .8)

	@media screen and (max-width: 980px)
		.photos__header
			flex-direction: column-reverse
			align-items: flex-end
			gap: 1rem
