@import '../../../styles/global'

.position
	position: relative
	padding: .3rem .5rem .5rem
	width: 40vw
	border: 2px solid $color-border
	border-radius: 0.5rem

	@media screen and (min-width: 900px)
		width: auto
		height: auto

	&__name
		margin: .3rem 0
		font-weight: $weight-bold
		text-align: center

	&__img-placeholder
		position: relative
		width: 100%
		min-height: 12.5rem // 200/16
		margin-bottom: .5rem
		border-radius: 0.5rem
		background-color: rgba($color-border, .4)
		color: $color-cancel

		&::after
			content: ""
			display: block
			padding-bottom: 100%

		svg
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			font-size: 4rem

	&__img
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		display: block
		width: 100%
		height: 100%
		max-width: 16.25rem // 260/16
		max-height: 16.25rem // 260/16
		object-fit: cover
		object-position: center
