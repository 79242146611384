@import '../../../styles/global'

.systems-group-list,
.systems-group-list--primary
  position: relative
  display: flex
  flex-direction: column
  align-items: flex-start
  flex-basis: 23rem
  flex-shrink: 0
  max-height: calc( 100vh - 330px )
  padding: 1rem 1rem 1rem 0
  border: 2px solid $color-border
  border-radius: 1rem
  overflow-x: hidden
  overflow-y: auto

  &::-webkit-scrollbar-thumb
    background-color: $color-background-light-grey
    border: 4px solid transparent
    border-radius: 8px
    background-clip: padding-box

  &::-webkit-scrollbar
    width: 1rem

  &__add-btn
    top: 0
    align-self: flex-end
    z-index: 1

  &__title
    align-self: flex-start
    margin: 2.2rem 1.5rem 1rem

  &__list-container
    align-self: stretch
    word-break: break-word

  &__item
    flex-grow: 1

  &__info,
  &__info--center,
  &__loading
    padding:
      left: 1.5rem
      right: 1rem
    font:
      size: .8rem
      style: italic
      weight: $weight-bold
    color: $color-text-dark

  &__loading
    font:
      size: .9rem
      style: normal

  &__info--center
    width: 80
    margin: 2rem auto 0
    font-size: 0.9rem
    line-height: 1.5
    text-align: center

.systems-group-list--primary
  flex-grow: 1
  flex-shrink: 1
  flex-basis: auto
  background-color: $color-primary-light

.systems-container
  display: flex
  gap: 1rem
  align-items: start
  margin: 1rem 0 -.5rem
