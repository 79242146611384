@import './styles/global'

// Custom styles
*, *::before, *::after
	box-sizing: border-box
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

body
	min-height: 100vh
	max-width: 100vw
	background-color: $color-white
	overflow-x: hidden

	*
		font-family: "Roboto", sans-serif

a
	color: inherit

a,
button
	cursor: pointer

.main-container
	min-height: 100vh
	padding:
		top: 5rem
		bottom: 2rem
	background-color: #fff

	&--view-part
		min-height: 0
		padding-bottom: 0

// helper classes
.sr-only
	position: absolute
	width: 0
	height: 0
	opacity: 0

.error,
.info,
.success
	padding: .3rem 0
	font:
		size: .8rem
		weight: $weight-bold

.error
	color: $color-error

.info
	padding: 0 0.4rem
	color: $color-text-dark
	font-style: italic
	word-break: break-word

.success
	color: $color-success

.loading
	display: inline-flex
	align-items: center
	justify-content: center
	gap: .6rem
	margin: 1rem
	font-size: 1rem

.flex-container,
.flex-container-no-margin,
.flex-column-container
	display: flex
	gap: 1rem
	align-items: center

.flex-container
	flex-direction: row
	margin: 1rem 0

	&--between,
	&--end,
	&--small
		display: flex

	&--start
		align-items: flex-start

	&--baseline
		align-items: baseline

	&--between
		align-items: baseline
		justify-content: space-between
		gap: 3rem

	&--center
		align-items: center

		.tags-list__list
			margin: 0

	&--end
		justify-content: flex-end

	&--small
		gap: .3rem
		margin-left: .5rem

.flex-column-container
	flex-direction: column

.basic-heading
	display: flex
	align-items: center
	justify-content: center
	text-align: center

.card-plane,
.card-plane__disabled
	width: 15.2rem
	padding-top: 1rem
	border: 1px solid transparent

	&:hover
		border: 1px solid $color-text-dark

	img
		border-radius: .5rem

	&__disabled
		opacity: 0.65

	&__image
		max-width: 85%
		margin: 0 auto

	&__content
		display: flex
		flex-direction: column
		align-items: center
		justify-content: space-between
		gap: .7rem
		padding: .7rem 1rem

	&__title
		display: flex
		align-items: center
		justify-content: space-between
		gap: 1rem
		width: 100%

.color-badge
	padding: .2rem .5rem
	border-radius: 3rem
	font-size: 0.8rem
	background-color: $color-border

.label
	font-weight: $weight-bold

	&--file
		display: block
		margin-bottom: .5rem
		font-weight: $weight-normal
		color: $color-text-dark

.expand
	flex-grow: 1

.checkbox
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%

	&__label
		display: block
		width: 100%
		height: 100%
		border-radius: .5rem
		cursor: pointer

		&:hover
			box-shadow: 0 0 2px 2px rgba($color-primary, .7)

	&__name,
	&__input
		position: absolute
		display: block
		width: 0
		height: 0
		opacity: 0

	&__input:checked ~ .checkbox__label
		box-shadow: 0 0 1px 4px $color-primary

.complete-info
	&__container
		display: flex
		align-items: center
		flex-shrink: 0
		gap: .5rem
		margin: 1rem 0
		font-size: .98rem
		color: $color-text-darker

	&__counter
		margin-right: 1.73rem

.configurations-list,
.panoramas-list,
.tags-list
	position: relative
	padding: 1.5rem
	border: 2px solid $color-border
	border-radius: 1rem
	margin-top: 3rem

	&__title
		margin-bottom: .3rem

	.add-button
		position: absolute
		right: 1rem
		top: 1rem

.panoramas-list
	margin-top: 1.5rem

	.list-item-text
		display: flex
		align-items: center
		justify-content: space-between
		gap: 2rem
		word-wrap: break-word

.bordered-top-element
	padding-top: .5rem
	border-top: 1px solid $color-border
