@import '../../../styles/global'

.component-list
	&__others-list,
	&__others-name.info
		color: $color-background-light-grey

	&__header
		padding: .5rem 0

	&__outer-expanded
		display: flex
		justify-content: center
		align-items: center
		padding: .5rem 1rem 1rem
