@import '../../../styles/global'

.dialog-container
	display: flex
	justify-content: center
	align-items: center
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	z-index: 1200
	background-color: rgba(#000, .6)

.dialog
	position: relative
	padding: 1rem
	min-width: 15rem
	max-width: 90%
	max-height: 95vh
	border-radius: 1.5rem
	background-color: $color-white
	box-shadow: $shadow
	overflow: hidden

	@media screen and (min-width: 420px)
		width: auto
		min-width: 22rem
		padding: 1.5rem

	@media screen and (min-width: 520px)
		min-width: 30rem
		padding: 1.5rem 2.3rem

	&--wide
		max-width: $global-width

	.dialog__btn-container
		display: flex
		gap: 1.5rem
		justify-content: flex-end
		margin-top: 1rem

		.button
			margin: 0
			font-size: 1.2rem

	&--loader
		line-height: 50px

	&__content
		position: relative
		max-height: 80vh
		padding: .5rem 0
		line-height: 1.5
		overflow: auto

		&--no-dialog-buttons
			max-height: 90vh

	&__text
		margin-bottom: 1rem
		font-weight: $weight-bold
		line-height: 1.5

		&--label
			display: block
			margin-bottom: .6rem

		span
			display: block
			font-weight: $weight-normal
