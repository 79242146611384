@import '../../../styles/global'

.image-input,
.image-input--drag-effect
	position: relative
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	gap: 1rem
	padding: .4rem 1rem 1.4rem
	border: 4px dashed $color-cancel
	border-radius: .5rem
	margin: 1rem 0
	background-color: $color-white-grey
	transition: .2s

.image-input--drag-effect
	border: 4px solid $color-primary
	background-color: #fff

.image-input
	&__input
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		opacity: 0
		cursor: pointer
		overflow: hidden

	&__label
		position: relative
		display: flex
		flex-direction: column
		align-items: center
		cursor: pointer
		pointer-events: none
		z-index: 1

	&__info
		display: flex
		flex-direction: column
		gap: .3rem
		margin-bottom: 0.5rem
		font-size: 1.4rem
		text-align: center
		color: $color-text-light
		opacity: .6

		&--secondary
			font-size: 1rem

	&__upload-info
		padding-top: .6rem
		font-size: .8rem
		color: $color-text-light
