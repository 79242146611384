@import '../../../styles/global'

.details-header
	&__buttons
		margin-left: auto

	&__title,
	&__title-name
		display: flex
		align-items: center

	&__title-name
		gap: 1rem
		flex-direction: column

		@media screen and (min-width: 670px)
			flex-direction: row

	&__title
		flex-direction: column-reverse
		margin-top: 1.5rem

	&__title
		@media screen and (min-width: 900px)
			flex-direction: row
			gap: 1rem

	&__title-icon
		transform: translateY(2px)

	&__details-container
		display: flex
		flex-direction: column
		gap: .4rem
		margin-top: .9rem

		@media screen and (min-width: 620px)
			flex-direction: row

	&__details
		padding-left: 1rem
		border-left: 2px solid $color-cancel
		margin-bottom: 1.5rem
		margin-left: 1rem
		color: $color-text-darker
		word-break: break-word

		&__item
			display: flex
			align-items: center
			gap: .5rem
			margin-bottom: 0.3rem
			margin-right: 1rem

			&:first-child
				margin-right: 0.6rem

			&--name
				align-self: flex-start
				flex-shrink: 0
				font-weight: bold

			&--wrap
				margin-bottom: 0.4rem
