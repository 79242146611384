@import '../../../styles/global'

.main-header__logo
	max-width: 6rem

.user
	display: flex
	align-items: center

	&__switch
		padding: 0 .3rem 0 .8rem
		border-radius: 3rem
		margin-right: 1rem
		background-color: rgba($color-primary-light, .3)

		label *
			font:
				size: 0.8rem
				weight: 700

	&__isAdmin-label
		display: inline-flex
		padding: .3rem .5rem
		border-radius: 3rem
		margin-right: 0
		font:
			size: 0.6rem
			weight: 700
		text-transform: uppercase
		background-color: $color-primary

		@media screen and (min-width: $brakpoint-medium)
			position: relative
			top: -0.8rem
			margin-right: .3rem

	&__name
		display: none

		@media screen and (min-width: $brakpoint-medium)
			display: block

	&__name--mobile
		display: block

		@media screen and (min-width: $brakpoint-medium)
			display: none
