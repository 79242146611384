@import '../../../styles/global'

.positions
	display: flex
	flex-wrap: wrap
	justify-content: center
	gap: .5rem
	margin-top: 5rem

	&__preview
		width: 80vw
		height: 70vh

	@media screen and (min-width: 900px)
		display: grid
		grid-template-columns: repeat(4, 1fr)
		grid-template-rows: repeat(3, auto)
		grid-template-areas: ". up . ." "left front right back" ". down . ."

.position
	font-size: 0.9rem
	text-transform: uppercase

	&--left
		grid-area: left

	&--right
		grid-area: right

	&--up
		grid-area: up

	&--down
		grid-area: down

	&--front
		grid-area: front

	&--back
		grid-area: back
