@import '../../../styles/global'

$lists-width: 25rem // 400/16
	
.configurator
	position: relative
	padding: 0 0 0 1.5rem
	width: 100vw
	height: calc(100vh - 12.5rem)

	&__panorama
		width: 100%
		max-width: calc(100vw - $lists-width)
		height: calc(100vh - 13rem)

	&__dropdown
		.configurator__panorama-name
			width: auto
			flex-grow: 0
			margin-right: 1.5rem

	&__panorama-grid
		display: flex
		gap: 1rem

	&__panorama-container
		position: relative
		flex-grow: 1

	&__panorama-lists
		width: $lists-width
		max-height: calc(100vh - 13rem)
		padding-right: 1.5rem
		overflow: auto

.configurator__menu-item
	min-width: 100%

.configurator__menu-item:disabled.Mui-disabled
	width: 100%
	color: $color-primary
	opacity: 1
	cursor: default
	font-weight: $weight-bold

.legend
	&__instruction
		display: flex
		justify-content: center
		align-items: center
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		border: none
		z-index: 1
		box-shadow: none
		background-color: rgba(0,0,0,.8)

	&__instruction-list
		display: flex
		justify-content: space-around
		align-items: baseline
		gap: 1.5rem
		min-width: 70%
		padding: 0 2rem

	&__instruction-item
		align-self: baseline
		justify-self: center
		display: flex
		flex-direction: column
		align-items: center
		gap: .5rem
		min-width: 33%
		color: #fff

		svg
			font-size: 1.875rem

		&:nth-child(2) .legend__image
			width: 2.5rem

		&:nth-child(3) .legend__image
			width: 2.9rem
			transform: translateY(0.3rem)

		&:nth-child(3) .legend__button
			display: flex
			justify-content: center
			align-items: center
			width: 1.7rem

	&__image
		width: 4.2rem

	&__container
		position: relative

	&__button-wrapper
		position: absolute
		top: 64%
		left: 74%
		display: flex
		gap: .5rem

	&__button
		display: block
		padding: .2rem .7rem
		border: 1px solid #fff
		border-radius: .3rem
		font-size: .77rem
		letter-spacing: 1px

	&__instruction-main
		display: block
		margin-top: 1.3rem
		font:
			size: .9rem
			weight: $weight-bold

	&__instruction-secondary
		font-size: .9rem
		line-height: 1.3

	&__shortcuts
		position: absolute
		top: 1rem
		left: 1rem
		display: flex
		gap: 1rem
		z-index: 1

		.flex-container
			margin: 0

	&__zoom,
	&__zoom--zoomed
		font:
			size: .7rem
			weight: $weight-bold

	&__zoom--zoomed
		color: $color-primary

	&__shortcut-item
		display: flex
		justify-content: center
		align-items: center
		width: 3rem
		height: 3rem
		border-radius: .3rem
		background-color: rgba(#fff, .6)

		&--zoom
			display: flex
			flex-direction: column
			gap: .1rem
			justify-content: center
			align-items: center

		p
			position: absolute
			top: 4rem
			left: 0
			width: auto
			min-width: 19rem
			text-align: center
			opacity: 0
			transition: opacity .3s ease
			padding: .3rem .5rem
			background-color: rgba(#fff, .6)
			border-radius: .3rem
			font-size: 1rem

			b
				font-weight: $weight-bold
				color: $color-primary

		&:hover
			p
				opacity: 1

	button.legend__shortcut-item:not(:disabled)
		box-shadow: 0 0 1px 3px rgba($color-primary, .3)

		&:hover
			box-shadow: 0 0 0 3px $color-primary
			background-color: rgba($color-primary-lite, .8)

	&--preview p
		min-width: 15rem
