@import '../../../styles/global'

.set-list
	padding-bottom: 1rem
	border-bottom: 2px dashed $color-primary
	margin-bottom: 1rem

	*
		word-break: break-word

	&__toggle-button
		display: flex
		align-items: center
		justify-content: space-between
		gap: 2rem
		width: 100%
		padding: 0.2rem 0 0.3rem 0.5rem
		border-radius: 5px
		font-size: 1.1rem
		cursor: pointer

		&:hover
			background-color: $color-hover
