@import '../../../styles/global'

.basic-form
	display: flex
	flex-direction: column
	gap: 1rem
	width: 100%
	max-width: 31.25rem // 500/16
	margin: 1rem auto

	form
		display: flex
		flex-direction: column
		gap: 1.5rem
		padding: .5rem 0

	&--in-content
		padding: 1rem
		border-radius: .5rem
		margin: .5rem 0

	&--temporary
		background-color: rgba($color-primary, .1)

	&--details
		display: flex
		flex-direction: column
		gap: .4rem
