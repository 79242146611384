@import '../../../styles/global'

.vehicle-info
	display: block
	max-width: 94vw
	padding: .5rem 1.2rem
	border: 2px solid $color-border
	border-radius: 4rem
	margin: 0 auto
	background-color: $color-light-background

	&__title
		display: flex
		flex-direction: column
		align-items: center
		gap: 1rem

		@media screen and (min-width: 670px)
			flex-direction: row

	&__icon
		padding-top: 0.05rem

	&__item
		font-size: .9rem
		word-break: break-word

		&--name
			font-weight: 500
			word-break: break-word

	&__permission
		flex-shrink: 0
		padding-bottom: 0.2rem
		margin: 0 auto
		font-weight: 700

		@media screen and (min-width: 670px)
			margin: 0 0 0 auto
