
@import '../../../styles/global'

.page-not-found
	position: relative
	max-width: 60rem
	margin: 15rem auto 0
	text-align: center

	&__404
		position: absolute
		top: -15rem
		left: 50%
		transform: translateX(-50%)
		font:
			size: 15rem
			weight: $weight-bold
		opacity: .1
		color: $color-text-dark
		z-index: -1

		@media (min-width: 600px)
			top: -18.3rem
			font-size: 30rem

	&__title
		margin-bottom: 3rem
		font:
			size: 2.6rem
			weight: $weight-bold
		line-height: 1.4

	&__sub-title
		margin-bottom: 3rem
		font-size: 1.8rem
		line-height: 1.4
		color: $color-text-dark

	.button
		max-width: 20rem
		margin: 0 auto