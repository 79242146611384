@import '../../../styles/global'

.default-list-item
  display: flex
  align-items: stretch
  gap: .5rem
  border-bottom: 2px solid $color-lighter-background

  &:last-child
    border-bottom: none

  &__item
    flex-grow: 1

  &__btn-container
    display: flex
    gap: .4rem
    padding:
      left: 1rem
      top: 0.1rem
