// VARS
$color-white: #fff
$color-white-grey: #eee
$color-background-base: #535353
$color-background-light-grey: #76787c
$color-border: #dbdbdb
$color-error: #ff0030
$color-hover: #F5F5F5
$color-light-background: #EFEFEF
$color-lighter-background: #fafafa
$color-light-blue: #AECCED
$color-success: #14b77f
$color-text-dark: #555555
$color-text-darker: #3a3a3a
$color-text-light: #808080
$color-warning: #ffff00
$color-cancel: #d1d1d1
$color-cancel-dark: lighten($color-text-dark, 25%)
$color-primary: #027AFF
$color-primary-light: #e7f3ff
$color-primary-lite: #a7cbf3

$weight-normal: 400
$weight-bold: 700

$shadow: -5px 10px 30px -10px rgba(0,0,0,.2)
$shadow-dark: 5px 10px 10px -5px rgba(0,0,0,.5)
$shadow-left: -5px 0 30px -10px gba(0,0,0,.2)

$global-width: 1450px
$max-content-width: 120rem
$header-height: 9rem
$footer-height: 5.5rem

$brakpoint-medium: 1200px
