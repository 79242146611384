@import '../../../styles/global'

.scroll-container
  display: flex
  flex-direction: column
  gap: 1rem
  max-height: 51vh
  margin-top: .5rem
  overflow-y: auto
  overflow-x: hidden

  @media screen and (min-width: 620px)
    max-height: 64vh

  &::-webkit-scrollbar-thumb
    background-color: $color-background-light-grey
    border: 7px solid transparent
    border-radius: 1rem
    background-clip: padding-box

  &::-webkit-scrollbar
    width: 1.2rem
