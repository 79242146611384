@import '../../../styles/global'
	
.vehicle-miniature
	position: relative
	width: 6rem
	height: 6rem
	flex-shrink: 0
	padding: .3rem
	border: 2px solid $color-border
	border-radius: .5rem

	img
		width: 100%
		height: 100%
		object-fit: cover
		border-radius: .5rem

	&__is-active
		position: absolute
		bottom: 0
		right: 0
		display: flex
		justify-content: center
		align-items: center
		width: 1.5rem
		height: 1.5rem
		background: #fff
		border-radius: 50%

	&--small
		width: 3rem
		height: 3rem
		padding: .2rem

		svg
			font-size: 1rem

		.vehicle-miniature__is-active
			width: 1rem
			height: 1rem
