.panorama-preview__container
	height: 35vw
	border: 1px solid

	@media screen and (min-width: 550px)
		width: 90vw

	@media screen and (min-width: 720px)
		height: 37vw
		max-height: 28rem
		max-width: 124rem

	@media screen and (min-width: 1500px)
		height: 52vw
		max-height: 75vh
		width: 80vw

